import { createSlice } from "@reduxjs/toolkit";

export const WhiseTokenSlice = createSlice({
    name: "whiseToken",
    initialState: {
        whiseToken: null,
    },
    reducers: {
        settoken: (state, action) => {
            state.whiseToken = action.payload;
        },
        cleartoken: (state, action) => {
            state.whiseToken = null;
        }
    }
})

export const { settoken, cleartoken } = WhiseTokenSlice.actions;

export default WhiseTokenSlice.reducer;