import { createSlice } from "@reduxjs/toolkit";

export const EmailVerificationSlice = createSlice({
    name: "mailVerificationDetail",
    initialState: {
        mailVerificationDetail: null,
    },
    reducers: {
        setMailVerificationDetail: (state, action) => {
            state.mailVerificationDetail = action.payload;
        },
        clearMailVerificationDetail: (state, action) => {
            state.mailVerificationDetail = null;
        }
    }
})

export const { setMailVerificationDetail, clearMailVerificationDetail } = EmailVerificationSlice.actions;

export default EmailVerificationSlice.reducer;