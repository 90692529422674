import React, { Component } from 'react';
import * as ReactDOM from "react-dom";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useEffect } from "react";
import { settoken } from "../features/WhiseTokenSlice";
import { useSelector, useDispatch } from "react-redux";
import { useState } from 'react';
import axios from 'axios';
import { Datepicker, DatepickerEvent } from "@meinefinsternis/react-horizontal-date-picker";
import { EventModal } from "./EventModal";
import { variables } from '../Variables';
import { clearMailVerificationDetail } from "../features/EmailVerificationSlice";

import propMainImg from '../assets/images/Rectangle_68.svg';
import logo from '../assets/images/logo.svg';
import locationPin from '../assets/images/location_pin.svg';
import phone from '../assets/images/phone.svg';
import bed from '../assets/images/bed.svg';
import p_shower from '../assets/images/p_shower.svg';
import lift from '../assets/images/lift.svg';
import p_img_1 from '../assets/images/prop_img_1.svg';
import p_img_2 from '../assets/images/prop_img_2.svg';
import p_img_3 from '../assets/images/prop_img_3.svg';
import p_img_4 from '../assets/images/prop_img_4.svg';
import phoneCall from '../assets/images/PhoneCall.svg';
import bathtub from '../assets/images/Bathtub.svg';
import shower from '../assets/images/Shower.svg';
import wifiHigh from '../assets/images/WifiHigh.svg';
import televisionSimple from '../assets/images/TelevisionSimple.svg';
import coffee from '../assets/images/Coffee.svg';
import megaphone from '../assets/images/megaphone.svg';
import closeIcon from '../assets/images/cross-close.svg';

export const Home = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 991);
    const [calendarActions, setCalendarActions] = useState([]);
    const [clientEstates, setClientEstates] = useState([]);
    const [selectedEstate, setSelectedEstate] = useState(null);
    const [whiseCalendarEvents, setWhiseCalendarEvents] = useState([]);
    const [clientToken, setClientToken] = useState("");
    const [confirmedIds, setConfirmedIds] = useState([]);
    const [tobeConfirmedIds, setTobeConfirmedIds] = useState([]);
    const [freeSlotIds, setFreeSlotIds] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [events, setEvents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [clientSettings, setClientSettings] = useState(null);
    const [whiseOffice, setWhiseOffice] = useState(null);
    const [xCoordinate, setXCoordinate] = useState("");
    const [yCoordinate, setYCoordinate] = useState("");
    const [mapUrl, setMapUrl] = useState("");
    const [time, setTime] = useState("");
    const [estatePurpose, setEstatePurpose] = useState(null);
    const [isAnyDateSelected, setIsAnyDateSelected] = useState(false);
    const [formStep, setFormStep] = useState(1);
    const [prevSelectedEventSlot, setPrevSelectedEventSlot] = useState(null);
    const [announcementMessage, setAnnouncementMessage] = useState("Quickly book the next slot");
    const [openBookSlotFormInSmallScreen, setOpenBookSlotFormInSmallScreen] = useState(false);

    let purpose = [{ "id": 1, "name": "for sale" }, { "id": 2, "name": "for rent" }, { "id": 3, "name": "life annuity sale" }];

    let startValueForDateSlider = new Date();
    let firstMatchFound = false;
    let eventExist = false;

    const [date, setDate] = useState(startValueForDateSlider);

    var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const { propertyId, clientId, officeId, contactId, languageId } = useParams();
    const whiseUserToken = useSelector(state => state.whiseUser.whiseToken);
    const dispatch = useDispatch();

    const handleClose = () => {
        setPrevSelectedEventSlot(selectedSlot);
        setShowModal(false)
    };

    const handlerBookSlot = async () => {
        let body = {}
        if (selectedSlot != null) {
            setShowModal(true);
            dispatch(clearMailVerificationDetail());
            body.CalendarIds = [+selectedSlot.id]
            await getWhiseCalenderEventById(body);
            if (eventExist) {
                setShowModal(true);
            } else {
                alert("This Slot is already booked and not available please try an Other slot")
            }
        }
        else {
            alert("please choose a slot");
        }

    }

    const getWhiseCalenderEventById = async (filters) => {
        let url = "https://api.whise.eu/v1/calendars/list";
        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };
        let body = {
            "Filter": filters
        };
        await axios.post(url, body, config)
            .then(response => {
                if (response.data.calendars) {
                    eventExist = true;
                } else {
                    eventExist = false;
                }
            })
            .catch(error => {
                console.log("error", error);
            });
    }

    useEffect(() => {
        if (propertyId) {
            getToken();
        }
    }, [propertyId, date]);

    const getToken = async () => {
        let url = "https://api.whise.eu/token";
        let headers = {
            'Content-Type': 'application/json'
        };
        let body = {
            "Username": variables.WhiseUser,
            "Password": variables.WhisePassword
        };

        try {
            let resp = await axios.post(url, body,
                {
                    headers: headers
                });
            if (resp && resp.data && resp.data.token) {
                dispatch(settoken(resp.data.token));
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (whiseUserToken != null) {
            getClientToken();
            getClientSettings();
            getWhiseOffices();
        }
    }, [whiseUserToken])

    const getClientToken = () => {
        let url = "https://api.whise.eu/v1/admin/clients/token";

        const config = {
            headers: {
                'Authorization': `Bearer ${whiseUserToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, { "clientId": +clientId }, config)
            .then(response => {
                setClientToken(response.data.token);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const getClientSettings = () => {
        let url = 'https://api.whise.eu/v1/admin/clients/settings';

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${whiseUserToken}`
        };

        let body = {
            ClientId: clientId
        };

        axios.post(url, body,
            {
                headers: headers
            })
            .then(response => {
                setClientSettings(response.data.settings);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const getWhiseOffices = () => {
        let url = "https://api.whise.eu/v1/admin/offices/list";

        const config = {
            headers: {
                'Authorization': `Bearer ${whiseUserToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, { "clientId": clientId }, config)
            .then(response => {
                console.log(response.data.offices);
                if (response.data.offices != undefined) {
                    var _filteredOffice = response.data.offices.filter((item) => { return item.id == officeId });
                    setWhiseOffice(_filteredOffice[0]);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        if (clientToken != null && clientToken != undefined && clientToken != "") {
            getClientWhiseEstatesList();
        }
    }, [clientToken])

    const getClientWhiseEstatesList = () => {
        let url = "https://api.whise.eu/v1/estates/list";
        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, {
            "Filter": {
                "StatusIds": [1],
                "PurposeStatusIds": [1, 2, 5, 6, 10, 11, 12, 13, 15, 16, 20, 21, 27, 28],
                "DisplayStatusIds": [1, 2, 3, 4, 5],
                "EstateIds": [+propertyId]
            }
        }, config)
            .then(response => {
                setClientEstates(response.data.estates);
            })
            .catch(error => {
                console.log("error", error);
            });
    }

    useEffect(() => {
        if (clientEstates != undefined && clientEstates.length > 0) {
            getCalendarActions();
            let filteredEstate = clientEstates.filter(item => {
                return item.id == propertyId
            })
            if (filteredEstate[0].details != undefined && filteredEstate[0].details.length > 0) {
                filteredEstate[0].details.forEach(item => {
                    if (item.id == 1849) {
                        setXCoordinate(item.value);
                    }
                    if (item.id == 1850) {
                        setYCoordinate(item.value);
                    }
                })

            }
            setSelectedEstate(filteredEstate[0]);
        }
    }, [clientEstates])

    useEffect(() => {
        if (selectedEstate != null) {
            //setEstatePurpose

            let estatePurpose = purpose.filter(item => {
                return item.id == selectedEstate.purpose.id
            })

            if (estatePurpose.length > 0) {
                setEstatePurpose(estatePurpose[0]);
            }
        }
    }, [selectedEstate])

    const getCalendarActions = () => {
        let url = "https://api.whise.eu/v1/calendars/actions/list";

        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, {}, config)
            .then(response => {
                setCalendarActions(response.data.calendarActions);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        if (calendarActions.length > 0) {
            getOfficeSettings();
        }
    }, [calendarActions])

    const getOfficeSettings = () => {
        const jsonconfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `Settings/GetLocalSetting?officeId=` + officeId;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                let settings = response.data;
                let toBeConfirmed = [settings.visitToSaleBefore, settings.visitToRentBefore];
                let cofirmed = [settings.visitToSaleAfter, settings.visitToRentAfter];
                let freeslot = [settings.visitToSaleFreeSlot, settings.visitToRentFreeSlot];

                setTobeConfirmedIds(toBeConfirmed);
                setConfirmedIds(cofirmed);
                setFreeSlotIds(freeslot);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const getWhiseCalenderEvents = async (calendarActionIds) => {
        var eventLimit = 300;
        var pageOffset = 0;
        let listOfEvents = [];

        let date = new Date();

        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        firstDay.setMinutes(firstDay.getMinutes() - firstDay.getTimezoneOffset());
        firstDay.setMonth(firstDay.getMonth() + 0);
        let startDate = firstDay.toISOString();

        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        lastDay.setMinutes(lastDay.getMinutes() - lastDay.getTimezoneOffset());
        lastDay.setDate(lastDay.getDate() + 1);
        lastDay.setSeconds(lastDay.getSeconds() - 1);
        let endDate = lastDay.toISOString();

        let _response = await getWhiseCalendarEventsByPagination(clientToken, eventLimit, pageOffset, startDate, endDate)

        if (_response.totalCount != undefined && _response.totalCount > 300) {
            listOfEvents.push(..._response.calendars);
            let _afterDivison = _response.totalCount / 300;
            let pages = Math.ceil(_afterDivison);
            for (let i = 1; i < pages; i++) {
                pageOffset = eventLimit * i + 1;
                let _eventResponse = await getWhiseCalendarEventsByPagination(clientToken, eventLimit, pageOffset, startDate, endDate);
                if (_response.calendars != undefined && _eventResponse.calendars.length > 0) {
                    listOfEvents.push(..._eventResponse.calendars);
                }
            }

            let mappedActions = listOfEvents.filter(item => {
                if (item.startDateTime.includes("-05-17")) {
                    console.log(item);
                }
                return item.estates != undefined && item.estates[0].estateId == propertyId && calendarActionIds.indexOf(item.action.id) != -1;
            })
                .sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));

            setWhiseCalendarEvents(mappedActions);

            //check if free slot events exist for selected day
            let SelectedDate = date;
            let currentSelectedDateEvents = mappedActions.filter(item => {
                return new Date(item.startDateTime).getDate() == SelectedDate.getDate() && new Date(item.startDateTime) >= SelectedDate;
            })
            //update announcement message if free slot event exist for selected day
            if (currentSelectedDateEvents.length > 0) {
                setAnnouncementMessage("Pick a slot that will suits you on");
            }
        }
        else if (_response.calendars != undefined && _response.calendars.length > 0) {
            listOfEvents.push(..._response.calendars);
            let mappedActions = listOfEvents.filter(item => {
                if (item.startDateTime.includes("-05-17")) {
                    console.log(item);
                }
                return item.estates != undefined && item.estates[0].estateId == propertyId && calendarActionIds.indexOf(item.action.id) != -1;
            })
                .sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));

            setWhiseCalendarEvents(mappedActions);

            //check if free slot events exist for selected day
            let SelectedDate = date;
            let currentSelectedDateEvents = mappedActions.filter(item => {
                return new Date(item.startDateTime).getDate() == SelectedDate.getDate() && new Date(item.startDateTime) >= SelectedDate;
            })
            //update announcement message if free slot event exist for selected day
            if (currentSelectedDateEvents.length > 0) {
                setAnnouncementMessage("Pick a slot that will suits you on");
            }
        }
        else {
            setWhiseCalendarEvents([]);
        }
    }

    const getWhiseCalendarEventsByPagination = async (clientToken, eventLimit, pageOffset, startDate, endDate) => {

        let url = "https://api.whise.eu/v1/calendars/list";
        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };

        let body = {
            "Filter": {
                "DateTimeRange": {
                    "Min": startDate,
                    "Max": endDate
                }
            },
            "Page": {
                "Limit": eventLimit,
                "Offset": pageOffset
            }
        };
        let response = await axios.post(url, body, config);

        return response.data
    }

    const getStatus = (actionId) => {
        if (tobeConfirmedIds.indexOf(actionId) !== -1) {
            return "To Be Confirmed";
        }
        else if (confirmedIds.indexOf(actionId) !== -1) {
            return "Confirmed";
        } else if (freeSlotIds.indexOf(actionId) !== -1) {
            return "Free Slot";
        } else {
            return '';
        }
    }

    const getAction = (actionId) => {
        let action = calendarActions.filter((d) => d.id === actionId);
        return action[0].name;
    }

    const onSelectedDay = (d) => {
        const [startValue, selectedDate] = d;
        if (startValue == null && selectedDate == null) {
            return;
        }
        let isBackward = false;
        if (date.getDate() == selectedDate.getDate()) {
            isBackward = true;
        }
        else {
            isBackward = false;
        }
        let clickedDate = 0;
        if (isBackward == true) {
            clickedDate = startValue.getDate() < 10 ? "0" + startValue.getDate() : startValue.getDate();
        }
        else {
            clickedDate = selectedDate.getDate() < 10 ? "0" + selectedDate.getDate() : selectedDate.getDate();
        }
        //let clickedDate = isBackward == true ? (startValue.getDate() < 10 ? "0" + startValue.getDate() : startValue.getDate()) : (selectedDate.getDate() < 10 ? "0" + selectedDate.getDate() : selectedDate.getDate());
        let month = monthNames[isBackward == true ? startValue.getMonth() : selectedDate.getMonth()];
        let slotsList = document.getElementsByClassName(month + clickedDate);
        let elementClassList = slotsList.length > 0 ? document.getElementsByClassName(month + clickedDate)[0].classList : "";

        if (elementClassList == "" || (elementClassList != "" && [...elementClassList].includes('has-event') == false)) {
            return;
        }
        setIsAnyDateSelected(true);

        let currenDateTime = new Date();
        if (startValue < date) {
            if (startValue.getDate() == currenDateTime.getDate()) {
                startValue.setHours(currenDateTime.getHours())
                startValue.setMinutes(currenDateTime.getMinutes() - currenDateTime.getTimezoneOffset());
            }
            setDate(startValue)
        }
        else {
            if (selectedDate.getDate() == currenDateTime.getDate()) {
                selectedDate.setHours(currenDateTime.getHours())
                selectedDate.setMinutes(currenDateTime.getMinutes() - currenDateTime.getTimezoneOffset());
            }
            setDate(selectedDate)
        }
        setSelectedSlot(null);

        let dateFilter = isBackward == true ? startValue : selectedDate;

        //check if free slot events exist for selected day
        let currentSelectedDateEvents = whiseCalendarEvents.filter(item => {
            return new Date(item.startDateTime).getDate() == dateFilter.getDate() && new Date(item.startDateTime) >= dateFilter;
        })
        //update announcement message if free slot event exist for selected day
        if (currentSelectedDateEvents.length > 0) {
            setAnnouncementMessage("Pick a slot that will suits you on");
        }
        else {
            setAnnouncementMessage("Quickly book the next slot");
        }
    }

    const handlerSelectedSlot = async (e) => {
        setTime(e.target.innerHTML);
        let dateItem = JSON.parse(e.target.getAttribute("event"));
        setFormStep(1);
        let body = {}
        body.CalendarIds = [+dateItem.id]
        await getWhiseCalenderEventById(body);
        if (eventExist) {
            //document.querySelector(".available-hour").classList.remove("active");
            //e.target.classList.add("active");
            let dateItem = JSON.parse(e.target.getAttribute("event"));
            setSelectedSlot(dateItem);
        } else {
            alert("This Slot is already booked and not available please try an Other slot")
        }
    }

    const getToBeConfirmedIds = (purposeId) => {
        if (tobeConfirmedIds != undefined) {
            if (purposeId == 1) {
                return tobeConfirmedIds[0];
            }
            else if (purposeId == 2) {
                return tobeConfirmedIds[1];
            }
        }
    }

    const handlerResetSelectedSlot = () => {
        setSelectedSlot(null);
    }

    useEffect(() => {
        if (freeSlotIds.length > 0 && clientToken != null) {
            let calendarActionIds = [freeSlotIds[0], freeSlotIds[1]];
            getWhiseCalenderEvents(calendarActionIds);
        }
    }, [freeSlotIds]);

    useEffect(() => {
        let dateItems = document.querySelectorAll(".datepicker-item");
        if (dateItems.length > 0 && whiseCalendarEvents.length > 0) {
            dateItems.forEach(item => {
                let currentYear = new Date().getFullYear();
                let month = item.parentElement.previousSibling.innerHTML;
                let day = item.childNodes[1].innerHTML;
                let date = new Date(month + "," + day + "," + currentYear);
                let currentDay = new Date().getDate();
                let events = whiseCalendarEvents;

                events.forEach(event => {
                    let eventDate = new Date(event.startDateTime).toDateString();
                    let sliderDate = date.toDateString();

                    //if ([...item.classList].includes('FC')) {
                    //    item.classList.remove("FC");
                    //}
                    item.classList.add(month + day);
                    if (eventDate == sliderDate && day == currentDay) {
                        item.classList.add("has-event");
                        item.classList.add("current-day");
                    }
                    else if (eventDate == sliderDate && day != currentDay) {
                        item.classList.add("has-event");
                    }
                    else if (day == currentDay) {
                        item.classList.add("current-day");
                    }
                })
            })
        }
    })

    useEffect(() => {
        if (yCoordinate != "" && xCoordinate != "") {
            let url = "https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/pin-s-l+ff0000(" + yCoordinate + "," + xCoordinate + ")/" + yCoordinate + "," + xCoordinate + ",14/675x420?access_token=pk.eyJ1IjoibWluZGZyZWUiLCJhIjoiY2xkbzlvOXJjMDd2ZTNucXZ5cmJ2aW9weSJ9.-Qp-drHs8IPsY7Oo5WZXRA";
            setMapUrl(url);
        }
    }, [yCoordinate, xCoordinate])

    const formatPrice = (price, symbol) => {
        let formattedPrice = new Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(price,);
        //let index = formattedPrice.indexOf(symbol);

        //let finalPrice = formattedPrice.substr(index+1)

        //return finalPrice + " " + symbol;
        return formattedPrice;
    }

    const clickHandleOpenBookSlotWindow = (e) => {
        if (e.target.nodeName == "p" || e.target.nodeName == "path" || e.target.nodeName == "svg") {
            document.getElementById("btnShowBookSlotWindow").classList.remove("d-block");
            document.getElementById("btnShowBookSlotWindow").classList.add("d-none");
        }
        else {
            e.target.classList.remove('d-block');
            e.target.classList.add('d-none');
        }
        document.getElementById("overlayDiv").classList.remove("d-none");
        document.getElementById("bookSlotForm").classList.remove("d-none", "d-md-none");
    }

    const handleCloseBookSlotWindow = () => {
        if (document.getElementById("btnShowBookSlotWindow") != null) {
            document.getElementById("bookSlotForm").classList.add("d-none", "d-md-none");
        }
        if (document.getElementById("btnShowBookSlotWindow") != null) {
            document.getElementById("btnShowBookSlotWindow").classList.remove("d-none");
            document.getElementById("btnShowBookSlotWindow").classList.add("d-block");
        }
        if (document.getElementById("overlayDiv") != null) {
            document.getElementById("overlayDiv").classList.add("d-none");
        }
    }

    useEffect(() => {
        if (isMobile == false) {
            handleCloseBookSlotWindow();
        }
    }, [isMobile])

    return (
        <>
            <div className={`overlay-div d-none`} id="overlayDiv"></div>
            {
                propertyId != undefined ?
                    <div className={`${isMobile == true ? "container-fluid" : "container"} p-3`}>
                        <div className="row align-items-center mb-5">
                            <div className="col-lg-3 col-md-3 col-sm-12">
                                {
                                    clientSettings != null && clientSettings.logoUrl != undefined ?
                                        <img src={clientSettings.logoUrl} className="main-logo" alt="Logo" />
                                        :
                                        <></>
                                }
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-12 pt-sm-5">
                                <div className="d-flex flex-row-reverse">
                                    <div className="d-flex align-items-center ms-5">
                                        <img src={phone} alt="phone icon" />
                                        <h5 className="fs-1-1 ms-2 mb-0">{whiseOffice != null && whiseOffice.telephone != undefined ? whiseOffice.telephone : ""}</h5>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img src={locationPin} alt="Location" />
                                        {
                                            whiseOffice != null ? <h5 className="fs-1-1 ms-2 mb-0">{(whiseOffice.address1 !== undefined ? whiseOffice.address1 + " " : "") + (whiseOffice.number !== undefined ? whiseOffice.number + ", " : "") + (whiseOffice.zip != undefined ? whiseOffice.zip + " " : "") + (whiseOffice.city != undefined ? whiseOffice.city : "")}</h5> : <h5 className="ms-2"></h5>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row flex-sm-row-reverse">
                            <div className="col-lg-4 mb-3 booking-section container-sm">
                                <div className="form-container d-none d-md-none d-lg-block d-xl-block d-xxl-block" id="bookSlotForm">
                                    <p className="mb-lg-0 d-flex justify-content-end"><img src={closeIcon} onClick={handleCloseBookSlotWindow} className="cursor-pointer d-lg-none d-xl-none d-xxl-none d-md-block d-sm-block" /></p>
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <h6 className="m-0">Book a visit</h6>
                                        {
                                            clientSettings != null && clientSettings.logoUrl != undefined ?
                                                <img src={clientSettings.logoUrl} alt="logo" width="70px" />
                                                :
                                                <></>
                                        }
                                    </div>
                                    <div className="slot-announcement mb-4">
                                        <img src={megaphone} alt="megaphone" />
                                        <span className="announcement">{announcementMessage},
                                            <span className="today-time">
                                                {
                                                    whiseCalendarEvents.length > 0 ? whiseCalendarEvents.map((item, i) => {
                                                        let selectedDate = date;
                                                        const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "today"];
                                                        let itemDate = new Date(item.startDateTime);

                                                        //bypass past events 
                                                        if (itemDate >= selectedDate) {

                                                            if (firstMatchFound == false && itemDate.getDate() == selectedDate.getDate()) {
                                                                let dayIndex = itemDate.getDay();
                                                                if (dayIndex === new Date().getDay() && itemDate.getDate() === new Date().getDate()) {
                                                                    dayIndex = 7;
                                                                }
                                                                let monthDate = selectedDate.getDate();
                                                                let monthIndex = selectedDate.getMonth();
                                                                firstMatchFound = true;
                                                                return (
                                                                    <span className="ms-1" key={i}>
                                                                        {daysOfWeek[dayIndex] + " " + monthDate + " " + monthNames[monthIndex] + "!"}
                                                                    </span>
                                                                )
                                                            }
                                                            else if (firstMatchFound == false) {
                                                                let dayIndex = itemDate.getDay();
                                                                if (dayIndex === new Date().getDay() && itemDate.getDate() === new Date().getDate()) {
                                                                    dayIndex = 7;
                                                                }
                                                                let monthDate = itemDate.getDate();
                                                                let monthIndex = itemDate.getMonth();
                                                                let hours = itemDate.getHours() < 10 ? "0" + itemDate.getHours() : itemDate.getHours();
                                                                let minutes = itemDate.getMinutes() < 10 ? "0" + itemDate.getMinutes() : itemDate.getMinutes();
                                                                var time = hours + ":" + minutes;
                                                                firstMatchFound = true;
                                                                return (
                                                                    <span className="ms-1" key={i}>
                                                                        {daysOfWeek[dayIndex] + " " + monthDate + " " + monthNames[monthIndex] + " at " + time + "!"}
                                                                    </span>
                                                                )
                                                            }
                                                        }
                                                    })
                                                        :
                                                        <></>
                                                }
                                            </span>
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <Datepicker
                                            onChange={onSelectedDay}
                                            startValue={date}
                                            classNames={{ dayItem: "datepicker-item", monthLabel: "months" }}
                                        />

                                    </div>
                                    <p className="hour-heading mb-2">Available slots</p>
                                    <div className="d-flex align-items-center mb-4">
                                        {
                                            whiseCalendarEvents.length > 0 ?
                                                whiseCalendarEvents.map((item, i) => {
                                                    let selectedDate = date;
                                                    let itemDate = new Date(item.startDateTime);

                                                    if (itemDate >= selectedDate && selectedDate.getDate() == itemDate.getDate() && selectedDate.getMonth() == itemDate.getMonth()) {
                                                        let hours = itemDate.getHours() < 10 ? "0" + itemDate.getHours() : itemDate.getHours();
                                                        let minutes = itemDate.getMinutes() < 10 ? "0" + itemDate.getMinutes() : itemDate.getMinutes();
                                                        var time = hours + ":" + minutes;
                                                        return (
                                                            <button className={`available-hour mb-0 me-1 ${selectedSlot != null && selectedSlot.id == item.id ? "active" : ""}`}
                                                                key={i} onClick={handlerSelectedSlot}
                                                                event={JSON.stringify(item)}>
                                                                {time}
                                                            </button>
                                                        )
                                                    }
                                                })
                                                :
                                                <></>
                                        }
                                    </div>
                                    {
                                        selectedSlot == null ?
                                            <button type="button" className="site-btn w-100 site-btn-padd disabled" disabled>Book a Slot</button>
                                            :
                                            <button type="button" className="site-btn w-100 site-btn-padd" onClick={handlerBookSlot}>Book a Slot</button>
                                    }
                                </div>
                                <button className="site-btn w-100 site-btn-padd booking-float-btn d-lg-none d-xl-none d-xxl-none d-block" id="btnShowBookSlotWindow" onClick={clickHandleOpenBookSlotWindow}>
                                    <FontAwesomeIcon icon={faChevronUp} /><br></br>
                                    Book Slot
                                </button>
                            </div>
                            <div className="col-lg-8">
                                <div className="main-img mb-5">
                                    <img src={selectedEstate != null && selectedEstate.pictures != undefined && selectedEstate.pictures.length > 0 ? selectedEstate.pictures[0].urlLarge : propMainImg}
                                        className="rounded-4" width="100%" alt="house image" />
                                </div>
                                <div className="form-container d-flex justify-content-between align-items-center position-relative mb-4">
                                    <div>
                                        <h4 className="heading mb-0 mt-2">{selectedEstate != null ? selectedEstate.name : ""}</h4>
                                        <p className="sab-heading mb-2">{selectedEstate != null ? formatPrice(selectedEstate.price, selectedEstate.currency) : 0}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="border-end pe-4 room-products">
                                            <img src={bed} alt="bed" className={`${selectedEstate != null && selectedEstate.bedRooms != undefined ? "me-1" : ""}`} />  {selectedEstate != null && selectedEstate.bedRooms != undefined ? selectedEstate.bedRooms : ""}
                                        </div>
                                        <div className="border-end px-4 room-products">
                                            <img src={p_shower} alt="shower" className={`${selectedEstate != null && selectedEstate.bathRooms != undefined ? "me-1" : ""}`} /> {selectedEstate != null && selectedEstate.bathRooms != undefined ? selectedEstate.bathRooms : ""}
                                        </div>
                                        <div className="ps-4 room-products">
                                            <img src={lift} alt="lift" className={`${selectedEstate != null && selectedEstate.area != undefined ? "me-1" : ""}`} /> {selectedEstate != null && selectedEstate.area != undefined ? <>{selectedEstate.area}<span className="ms-05">m</span><sup>2</sup></> : ""}
                                        </div>
                                    </div>
                                    {
                                        estatePurpose != null ?
                                            <div className="position-absolute text-center sale-tag">
                                                {
                                                    estatePurpose.name
                                                }
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>
                                <div className="d-flex justify-content-between mb-4 prop-images">
                                    {
                                        selectedEstate != null && selectedEstate.pictures != undefined && selectedEstate.pictures.length > 0 ? selectedEstate.pictures.map((item, i) => {
                                            return (
                                                <img src={item.urlLarge} alt="" className="room-image me-2" key={i} />
                                            )
                                        })
                                            :
                                            <></>
                                    }
                                </div>
                                <div className="form-container mb-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="heading">Description</h4>
                                        {
                                            whiseOffice != null && whiseOffice.website != undefined ?
                                                <a href={whiseOffice.website} className="url-link" target="_blank">{whiseOffice.website}</a>
                                                :
                                                <></>
                                        }

                                    </div>
                                    <p className="text-paragraph">
                                        {
                                            selectedEstate != null && selectedEstate.shortDescription != undefined && selectedEstate.shortDescription.length > 0
                                                ?
                                                selectedEstate.shortDescription.map(item => {
                                                    const markup = { __html: item.content };
                                                    return (
                                                        <p className="mb-0" dangerouslySetInnerHTML={markup}></p>
                                                    )
                                                })
                                                :
                                                ""
                                        }
                                    </p>
                                </div>
                                <div className="form-container">
                                    {
                                        mapUrl != "" ?
                                            <iframe
                                                width="100%"
                                                height="420px"
                                                src={mapUrl}
                                                title="Streets"
                                            ></iframe>
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div></div>
            }
            <EventModal showModal={showModal} handleClose={handleClose} clientToken={clientToken} eventSlot={selectedSlot}
                estate={selectedEstate} getAction={getAction} callbackGetToBeConfirmedIds={getToBeConfirmedIds}
                calendarActions={calendarActions} parent={getOfficeSettings} resetSelectedSlot={handlerResetSelectedSlot}
                officeId={officeId} clientId={clientId} clientSettings={clientSettings} time={time} formStep={formStep}
                setFormStep={setFormStep} prevSelectedEventSlot={prevSelectedEventSlot} />
        </>
    )
}
